import React from 'react'
import PropTypes from 'prop-types'

import AiBody from '../../../layout/component/AiBody'

const AiBodyWidget = props => {
  const { items, user, helpHeading, helpBody, disclaimer } = props
  return (
    <div className="widget widget--ai-body">
      <AiBody
        user={user}
        items={items}
        helpHeading={helpHeading}
        helpBody={helpBody}
        disclaimer={disclaimer}
      />
    </div>
  )
}

AiBodyWidget.propTypes = {
  items: PropTypes.array,
  user: PropTypes.object,
  helpHeading: PropTypes.string,
  helpBody: PropTypes.string,
  disclaimer: PropTypes.string
}

export default AiBodyWidget

import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './AiBody.styl'
import SuggestedPrompts from './component/SuggestedPrompts'
import Icon from '../primitive/Icon'
import AiAnswer from './component/AiAnswer'
import Video from '../video'
import Loading from '../primitive/Loading'
import Button from '../primitive/Button'
import HeadingSlash from '../primitive/HeadingSlash'

function QuestionBox({
  question,
  onChange,
  onKeyDown,
  onSubmit,
  textareaRef,
  className
}) {
  return (
    <div className={`${styles.AiBody_textarea} ${className || ''}`}>
      <textarea
        ref={textareaRef}
        value={question}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder="Ask your question here"
        className={styles.AiBody_textarea_input}
      />
      <button
        onClick={() => onSubmit()}
        disabled={!question.trim()}
        className={styles.AiBody_textarea_button}
      >
        <Icon
          width={22}
          type="ChevronRight"
          className={styles.AiBody_textarea_button_icon}
        />
      </button>
    </div>
  )
}

const AiBody = props => {
  const { items, user, helpHeading, helpBody, disclaimer } = props
  const [qaList, setQaList] = useState([])
  const [currentQuestion, setCurrentQuestion] = useState('')
  const textareaRef = useRef(null)

  useEffect(() => {
    ;(async function fetchSavedPrompt() {
      const searchParams = new URLSearchParams(window.location.search)
      const idFromQuery = searchParams.get('id')

      if (idFromQuery) {
        try {
          const response = await fetch('/api/ai/fetch-saved-prompt', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ responseId: idFromQuery, user: user._id })
          })
          const data = await response.json()

          if (data && data.content) {
            // All in ONE state update:
            setQaList(prev => {
              const newIndex = prev.length

              const newQAItem = {
                question: data.question || '',
                answer: '',
                sources: data.sources.sources || [],
                additionalSources: data.sources.additionalSources || [],
                answerId: data.responseId || null,
                isTyping: true,
                loading: false,
                loadingMessage: '',
                isOpen: true,
                errorMessage: ''
              }

              const updatedList = [...prev, newQAItem]

              setTimeout(() => {
                typeOutText(data.content, newIndex)
              }, 0)

              return updatedList
            })
          }
        } catch (error) {
          console.error('Error fetching the saved prompt:', error)
        }
      }
    })()
  }, [user._id])

  const loadingMessages = [
    'Spying on your competitors (ethically, of course)...',
    'Give me a sec to work my magic…',
    'Processing... (even AI needs a moment to think)',
    '‘Our objective is effectiveness; our strategy is creativity’ - Sir John Hegarty',
    '‘If your advertising goes unnoticed, everything else is academic’ - Bill Bernbach',
    '‘Managers don’t kill creativity on purpose. Yet in the pursuit of productivity, efficiency and control they undermine creativity’ - Teresa Amabile',
    'Mining for nuggets of marketing wisdom...',
    '‘Make sure your proposition is right, then let your people say it incisively, artfully, unforgettably’ - Bill Bernbach',
    '‘Give me the freedom of a tightly-written creative brief’ - David Ogilvy',
    '‘In blue oceans, competition is irrelevant because the rules of the game are waiting to be set’ - Blue Ocean Strategy',
    '‘Strategy involves focus and, therefore, choice. And choice means setting aside some goals in favour of others’ - Richard Rumelt',
    '‘Buyers “decide” not to consider the vast majority of brands on the market. Instead they notice only a few’ - Byron Sharp'
  ]

  const typeOutText = (fullText, index) => {
    let i = 0
    const interval = setInterval(() => {
      setQaList(prev => {
        const copy = [...prev]
        copy[index].answer += fullText[i]
        return copy
      })
      i++
      if (i >= fullText.length) {
        clearInterval(interval)
        setQaList(prev => {
          const copy = [...prev]
          copy[index].isTyping = false
          return copy
        })
      }
    }, 10)
  }

  const toggleAccordion = index => {
    setQaList(prev => {
      const copy = [...prev]
      copy[index].isOpen = !copy[index].isOpen
      return copy
    })
  }

  const handleSubmit = async overrideQuestion => {
    const questionToSubmit = overrideQuestion ?? currentQuestion

    if (!questionToSubmit.trim()) return

    setCurrentQuestion('')

    const randomIndex = Math.floor(Math.random() * loadingMessages.length)
    const chosenLoadingMessage = loadingMessages[randomIndex]
    const newIndex = qaList.length

    setQaList(prev => [
      ...prev,
      {
        question: questionToSubmit,
        answer: '',
        sources: [],
        additionalSources: [],
        answerId: null,
        isTyping: true,
        loading: true,
        loadingMessage: chosenLoadingMessage,
        isOpen: true,
        errorMessage: ''
      }
    ])

    try {
      const response = await fetch('/api/ai/question', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ question: questionToSubmit, userId: user._id })
      })

      if (!response.ok) {
        throw new Error(`Server returned status ${response.status}`)
      }

      const data = await response.json()

      if (data.error) {
        throw new Error(data.error)
      }

      setQaList(prev => {
        const copy = [...prev]
        copy[newIndex].sources = data.sources || []
        copy[newIndex].additionalSources = data.additionalSources || []
        copy[newIndex].answerId = data.responseId || null
        return copy
      })

      if (data.content) {
        typeOutText(data.content, newIndex)
      }
    } catch (error) {
      setQaList(prev => {
        const copy = [...prev]
        copy[newIndex].errorMessage = `
          Either we don’t have an answer for that right now or AI isn’t as intelligent as it thinks it is.<br /><br />
          <strong>Pro tips</strong>: Treat it like a human and try asking the question again in a slightly different way. It also seems to like longer prompts over short ones. If that still doesn’t work, the regular search is actually pretty good.<br /><br />
          But if you’re thinking ‘I just want to talk to a damn human’ then you can email <a href="mailto:support@contagious.com">support@contagious.com</a> or contact your account manager. Alternatively, if you have access to Ask Contagious, you can submit your specific marketing question to the team <a href="https://www.contagious.com/iq/ask-contagious" target="blank" rel="noopener noreferrer">here</a>.
        `
        return copy
      })
      console.error('Error fetching the answer:', error)
    } finally {
      setQaList(prev => {
        const copy = [...prev]
        if (copy[newIndex]) {
          copy[newIndex].loading = false
        }
        return copy
      })
    }
  }

  const handlePromptClick = prompt => {
    setCurrentQuestion(prompt.prompt)
    handleSubmit(prompt.prompt)
  }

  const handleInputChange = e => {
    setCurrentQuestion(e.target.value)
    const textarea = textareaRef.current
    if (textarea) {
      textarea.style.height = '32px'
      textarea.style.height = `${textarea.scrollHeight}px`
    }
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSubmit()
    }
  }

  const renderQAItem = (qaItem, index) => {
    return (
      <div key={index}>
        <div
          className={styles.AiBody_questionHeader}
          onClick={() => toggleAccordion(index)}
        >
          <h2 className={styles.AiBody_heading}>{qaItem.question}</h2>
          <Icon
            width="30"
            type={qaItem.isOpen ? 'ChevronUp' : 'ChevronDown'}
            className={styles.AiBody_accordionIcon}
          />
        </div>

        {qaItem.isOpen && (
          <div className={styles.AiBody_answerContainer}>
            {qaItem.loading && (
              <div className={styles.LoadingContainer}>
                <div className={styles.LoadingContainer_message}>
                  {qaItem.loadingMessage}
                </div>
                <Loading />
              </div>
            )}

            {qaItem.errorMessage ? (
              <div
                className={styles.ErrorMessage}
                dangerouslySetInnerHTML={{ __html: qaItem.errorMessage }}
              />
            ) : (
              <AiAnswer
                answer={qaItem.answer}
                sources={qaItem.sources}
                additionalSources={qaItem.additionalSources}
                answerId={qaItem.answerId}
                user={user}
              />
            )}
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      {qaList.length === 0 && (
        <div className={styles.AiContainer}>
          <div className={styles.AiBody}>
            <QuestionBox
              question={currentQuestion}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              onSubmit={() => handleSubmit()}
              textareaRef={textareaRef}
            />

            {/* <SuggestedPrompts onPromptClick={handlePromptClick} /> */}
            <div className={styles.AiBody_proTips}>
              <p className={styles.AiBody_proTips_heading}>Pro tips:</p>
              <ul className={styles.AiBody_proTips_list}>
                <li>Treat the AI like a human — describe exactly what you're looking for as if you're speaking to the Contagious team.</li>
                <li>Use as much detail as you can in your prompt.</li>
                <li>If your answer isn't quite right, trying asking again using slightly different language, e.g. rather than 'I'm looking for cases...' try asking 'I'm looking for campaigns/examples/etc'.</li>
                <li>The AI responds in English by default, but you can ask questions in your own language or request a response in a specific language, for example by adding 'Respond in Spanish'.</li>
                <li>If none of that works, try the regular search, which is still pretty good.</li>
              </ul>
            </div>

            <div className={styles.AiHelp}>
              {items &&
                items.map((item, index) => (
                  <div key={index} className={styles.AiHelp_item}>
                    <h3 className={styles.AiHelp_item_heading}>
                      <HeadingSlash>{item.title}</HeadingSlash>
                    </h3>
                    <Video
                      provider="vimeo"
                      videos={[{ videoId: item.video }]}
                    />
                  </div>
                ))}
              {/* <p className={styles.AiHelp_text}>
                For further questions and information have a look at our{' '}
                <a href="/iq/faqs">FAQs</a> and{' '}
                <a href="/terms-and-conditions">Terms of Use</a>
              </p> */}
            </div>
          </div>
        </div>
      )}

      {qaList.length > 0 && (
        <>
          <div className={`${styles.AiContainer} ${styles.AiContainer_full}`}>
            <div className={styles.AiBody}>{renderQAItem(qaList[0], 0)}</div>
          </div>

          {qaList.slice(1).map((qaItem, idx) => {
            const actualIndex = idx + 1
            return (
              <div
                key={actualIndex}
                className={`${styles.AiContainer} ${styles.AiContainer_full}`}
              >
                <div className={styles.AiBody}>
                  {renderQAItem(qaItem, actualIndex)}
                </div>
              </div>
            )
          })}

          <QuestionBox
            question={currentQuestion}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onSubmit={() => handleSubmit()}
            textareaRef={textareaRef}
            className={styles.AiBody_textarea__second}
          />
        </>
      )}

      {disclaimer && (
        <div className={styles.AiDisclaimer}>
          <div dangerouslySetInnerHTML={{ __html: disclaimer }} />
        </div>
      )}

      {qaList.length > 0 && (
        <div className={styles.AiHelpBanner}>
          <div className={styles.AiHelpBanner_inner}>
            <div className={styles.AiHelpBanner_text}>
              <h2>{helpHeading}</h2>
              <p>{helpBody}</p>
            </div>
            <Button
              className={styles.AiHelpBanner_button}
              href="/iq/ask-contagious"
              ariaLabel="Ask Contagious"
            >
              Ask <strong>Contagious</strong>
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

QuestionBox.propTypes = {
  question: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  textareaRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]).isRequired,
  className: PropTypes.string
}

AiBody.propTypes = {
  items: PropTypes.array,
  user: PropTypes.object,
  helpHeading: PropTypes.string,
  helpBody: PropTypes.string,
  disclaimer: PropTypes.string
}

export default AiBody
